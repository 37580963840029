import React from 'react';
import SingPassFooter from '../../components/Singpass/SingpassFooter';
import SingPassQR from '../../components/Singpass/SingpassQR';
import { CenterText } from '../../components/styles';
import * as TEXT from '../../constants/appCopy.json';

interface Props {
  authEndpoint?: string;
  clientId?: string;
  callback?: string;
}

const headView = (
  <>
    <CenterText style={{ lineHeight: 'normal' }}>
      {TEXT.COPY.singpass.bodycopy}
    </CenterText>
  </>
);

export default function SingpassLogin(props: Props) {
  const { authEndpoint, clientId, callback } = props;

  return (
    <>
      {headView}
      <SingPassQR authEndpoint={authEndpoint} clientId={clientId} callback={callback} />
      <SingPassFooter />
    </>
  );
}
