import React from 'react';
import OnePassLogin from './OnePassLogin';
import { Tabs, Text } from '@dls/web';
import styled from 'styled-components';
import media from '../../helpers/media';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { aemConfigState, flowConfigState } from '../..';
import SingpassLogin from './SingpassLogin';
import BoxWrapper from '../../components/Layout/BoxWrapper';
import SETTINGS from '../../constants/appSettings'
import { COPY } from '../../constants/appCopy.json';

const TabWrapper = styled.div`
  margin-bottom: 24px;
  ${media.sm`
    margin-bottom: 32px;
  `}
`;

const tabData = [{ title: COPY.tabs.onepass }, { title: COPY.tabs.singpass}];

export default function LoginView() {
  let [selectedTab, setSelectedTab] = useState(0);

  let aemConfig = useRecoilValue(aemConfigState);
  let flowConfig = useRecoilValue(flowConfigState);

  let isSingpassEnabled = aemConfig.singpass?.enabled && flowConfig?.singpass;
  const isGE = flowConfig?.client === 'GE';

  const onepassLoginView = <OnePassLogin isGE={isGE} />;

  let view = null;
  if (!isSingpassEnabled) {
    // without singpass
      view = onepassLoginView;
  } else {
    // with singpass
    if (selectedTab === 0) {
      view = onepassLoginView;
    } else {
      view = (
        <SingpassLogin
          authEndpoint={SETTINGS.SINGPASS_AUTH_URL}
          clientId={SETTINGS.SINGPASS_CLIENT_ID}
          callback={SETTINGS.SINGPASS_SUCCESS_CALLBACK}
        />
      );
    }
  }

  if(SETTINGS.MAINTENANCEBANNER){
    view = null;
    isSingpassEnabled = false;
  }

  return (
    <BoxWrapper>
      {isSingpassEnabled && (
        <TabWrapper>
          <Tabs
            tabs={tabData}
            onTabItemClick={(_e, index) => setSelectedTab(index)}
            selected={selectedTab}
          />
        </TabWrapper>
      )}
      {view}
      {SETTINGS.MAINTENANCEBANNERMESSAGE && <Text type="body">
        {SETTINGS.MAINTENANCEBANNERMESSAGE}</Text>
      }
    </BoxWrapper>
  );
}
