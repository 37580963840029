import React from "react";

export const SingpassLoginIcon = () => (
  <svg width="84" height="15" viewBox="0 0 84 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.13171 11.128C7.7023 11.128 9.2655 9.78509 9.2655 7.65497C9.2655 6.19631 8.3739 5.20071 6.5328 4.79552L4.72643 4.39034C4.04326 4.22827 3.6843 4.00831 3.6843 3.60312C3.6843 3.19794 4.0201 2.94325 4.84222 2.94325C5.79521 2.97646 6.70574 3.34552 7.41282 3.98516L8.9297 1.76242C7.73628 0.927766 6.29619 0.519896 4.84222 0.604753C2.43374 0.604753 0.812641 1.76243 0.812641 3.8115C0.79684 4.53887 1.05453 5.2457 1.53478 5.79229C2.01503 6.33889 2.6829 6.68548 3.40639 6.76356L5.20118 7.1919C6.09279 7.38871 6.3591 7.65498 6.3591 8.00228C6.3591 8.34958 6.00015 8.6853 5.20118 8.6853C4.06876 8.66308 2.97792 8.25462 2.10952 7.52763L0.5 9.6809C1.82499 10.6941 3.46545 11.2066 5.13171 11.128V11.128Z" fill="white"/>
    <path d="M12.2628 4.01932C12.6113 4.01932 12.9519 3.91581 13.2416 3.72194C13.5312 3.52807 13.7567 3.25257 13.8895 2.93035C14.0224 2.60814 14.0565 2.25374 13.9877 1.91209C13.9189 1.57044 13.7501 1.25692 13.5029 1.0113C13.2556 0.765671 12.941 0.598996 12.5989 0.532412C12.2568 0.465828 11.9026 0.502334 11.5813 0.637296C11.26 0.772257 10.986 0.999597 10.794 1.29049C10.602 1.58138 10.5008 1.92273 10.5031 2.27124C10.4999 2.50265 10.5434 2.73234 10.6308 2.94661C10.7183 3.16088 10.848 3.35536 11.0122 3.51847C11.1764 3.68158 11.3717 3.80998 11.5866 3.89603C11.8014 3.98207 12.0314 4.02401 12.2628 4.01932Z" fill="white"/>
    <path d="M10.3643 10.897H14.1614L13.3626 4.61084C12.6451 4.79611 11.8922 4.79611 11.1746 4.61084L10.3643 10.897Z" fill="white"/>
    <path d="M15.9463 10.894H19.0264V5.45295C19.0264 4.12163 19.6748 3.29969 20.9254 3.29969C22.1759 3.29969 22.7433 3.98271 22.7433 5.45295V10.894H25.7771V4.68889C25.7771 1.97994 24.4223 0.590737 22.1065 0.590737C21.5032 0.564511 20.9034 0.693339 20.3641 0.964923C19.8249 1.23651 19.3643 1.64177 19.0264 2.14202V1.49372C19.0353 1.39876 19.0232 1.30299 18.9908 1.21325C18.9585 1.12352 18.9068 1.04201 18.8393 0.974568C18.7718 0.907121 18.6903 0.855391 18.6006 0.823062C18.5108 0.790733 18.415 0.778603 18.32 0.787539H16.0042L15.9463 10.894Z" fill="white"/>
    <path d="M35.6995 0.789662C35.6045 0.780726 35.5087 0.792856 35.419 0.825185C35.3292 0.857515 35.2477 0.909245 35.1802 0.976691C35.1128 1.04414 35.061 1.12564 35.0287 1.21538C34.9963 1.30511 34.9842 1.40088 34.9932 1.49584V2.10941C34.6562 1.61326 34.1964 1.21281 33.6587 0.947041C33.121 0.681275 32.5236 0.559255 31.9246 0.59286C30.678 0.712788 29.5208 1.29271 28.6787 2.21951C27.8367 3.1463 27.3701 4.35348 27.3701 5.60558C27.3701 6.85767 27.8367 8.06486 28.6787 8.99165C29.5208 9.91844 30.678 10.4984 31.9246 10.6183C32.5039 10.6548 33.0835 10.5543 33.6166 10.325C34.1498 10.0957 34.6213 9.74407 34.9932 9.29855V9.97C34.9938 10.2871 34.9293 10.601 34.8036 10.8922C34.678 11.1834 34.4938 11.4457 34.2625 11.6628C34.0313 11.8799 33.7579 12.0472 33.4593 12.1542C33.1607 12.2613 32.8433 12.306 32.5268 12.2853C31.3898 12.3074 30.2875 11.8932 29.4467 11.1277L27.8603 13.443C29.2118 14.4641 30.8817 14.9728 32.5731 14.8785C35.6069 14.8785 38.0732 13.362 38.0732 9.55324V0.789662H35.6995ZM32.7352 7.94407C32.2772 7.94407 31.8294 7.80828 31.4486 7.55387C31.0677 7.29946 30.7709 6.93784 30.5956 6.51477C30.4203 6.0917 30.3745 5.62616 30.4638 5.17703C30.5532 4.72789 30.7738 4.31534 31.0976 3.99153C31.4215 3.66773 31.8342 3.44721 32.2834 3.35787C32.7326 3.26854 33.1983 3.31439 33.6214 3.48963C34.0446 3.66487 34.4063 3.96164 34.6608 4.34239C34.9152 4.72315 35.051 5.1708 35.051 5.62873C35.051 6.2428 34.8071 6.83171 34.3727 7.26593C33.9384 7.70014 33.3494 7.94407 32.7352 7.94407Z" fill="white"/>
    <path d="M46.5509 0.59112C45.9332 0.563774 45.3182 0.687625 44.7592 0.951916C44.2002 1.21621 43.7142 1.61294 43.3435 2.10767V1.4941C43.3524 1.39914 43.3403 1.30337 43.3079 1.21364C43.2756 1.1239 43.2239 1.0424 43.1564 0.974951C43.0889 0.907505 43.0074 0.855775 42.9177 0.823445C42.8279 0.791116 42.7321 0.778986 42.6371 0.787922H40.3213V14.5989H43.3435V9.78303C43.746 10.2295 44.2423 10.5814 44.7968 10.8136C45.3513 11.0458 45.9503 11.1524 46.5509 11.1259C47.2133 11.109 47.8652 10.9576 48.4672 10.6809C49.0692 10.4043 49.6086 10.0081 50.0527 9.51647C50.4967 9.02486 50.8362 8.4481 51.0503 7.82126C51.2645 7.19442 51.3489 6.53058 51.2984 5.8701C51.3522 5.20777 51.2702 4.54144 51.0576 3.91186C50.8449 3.28229 50.506 2.7027 50.0616 2.20856C49.6173 1.71442 49.0767 1.31612 48.4731 1.03801C47.8694 0.759901 47.2154 0.607838 46.5509 0.59112V0.59112ZM45.7404 8.42855C45.223 8.44935 44.7112 8.31483 44.2709 8.04231C43.8306 7.76978 43.482 7.37173 43.2699 6.89941C43.0578 6.42709 42.992 5.90211 43.081 5.39208C43.1699 4.88205 43.4095 4.41031 43.7689 4.0376C44.1284 3.66489 44.5911 3.40827 45.0977 3.30078C45.6043 3.1933 46.1314 3.23986 46.6113 3.43449C47.0911 3.62912 47.5017 3.96289 47.7902 4.39284C48.0787 4.82279 48.2318 5.32922 48.2299 5.84695C48.2474 6.18274 48.1957 6.51855 48.078 6.83353C47.9603 7.14852 47.7791 7.43597 47.5457 7.67803C47.3122 7.9201 47.0315 8.11163 46.721 8.24073C46.4104 8.36984 46.0767 8.43377 45.7404 8.42855V8.42855Z" fill="white"/>
    <path d="M61.3135 0.789335C61.2185 0.7804 61.1227 0.79253 61.0329 0.824859C60.9432 0.857188 60.8617 0.908918 60.7942 0.976364C60.7267 1.04381 60.675 1.12531 60.6426 1.21505C60.6103 1.30479 60.5982 1.40055 60.6071 1.49552V2.10908C60.2308 1.60596 59.7355 1.20405 59.1656 0.93937C58.5957 0.674693 57.9691 0.555522 57.3418 0.592533C56.6794 0.609446 56.0275 0.760842 55.4255 1.03752C54.8235 1.31421 54.2841 1.71039 53.84 2.202C53.396 2.6936 53.0565 3.27036 52.8424 3.8972C52.6282 4.52404 52.5438 5.18788 52.5943 5.84836C52.5438 6.50884 52.6282 7.17268 52.8424 7.79952C53.0565 8.42637 53.396 9.00312 53.84 9.49472C54.2841 9.98633 54.8235 10.3825 55.4255 10.6592C56.0275 10.9359 56.6794 11.0873 57.3418 11.1042C57.9687 11.1381 58.5943 11.0176 59.1637 10.7531C59.733 10.4887 60.2287 10.0885 60.6071 9.58764V10.8958H63.6409V0.789335H61.3135ZM58.1523 8.42997C57.4959 8.38604 56.8806 8.09435 56.4312 7.61395C55.9818 7.13355 55.7318 6.50035 55.7318 5.84257C55.7318 5.1848 55.9818 4.5516 56.4312 4.0712C56.8806 3.5908 57.4959 3.2991 58.1523 3.25518C58.8088 3.2991 59.424 3.5908 59.8734 4.0712C60.3228 4.5516 60.5729 5.1848 60.5729 5.84257C60.5729 6.50035 60.3228 7.13355 59.8734 7.61395C59.424 8.09435 58.8088 8.38604 58.1523 8.42997Z" fill="white"/>
    <path d="M69.7803 11.128C72.3509 11.128 73.9141 9.78509 73.9141 7.65497C73.9141 6.19631 73.0225 5.20071 71.1814 4.79552L69.3866 4.39034C68.6918 4.22827 68.3329 4.00831 68.3329 3.60312C68.3329 3.19794 68.6687 2.94325 69.4908 2.94325C70.4438 2.97646 71.3543 3.34552 72.0614 3.98516L73.5783 1.76242C72.3849 0.927766 70.9448 0.519896 69.4908 0.604753C67.0823 0.604753 65.4612 1.76243 65.4612 3.8115C65.4454 4.53887 65.7031 5.2457 66.1834 5.79229C66.6636 6.33889 67.3315 6.68548 68.055 6.76357L69.8498 7.1919C70.7414 7.38871 71.0077 7.65498 71.0077 8.00228C71.0077 8.34958 70.6371 8.6853 69.8498 8.6853C68.7173 8.66308 67.6265 8.25462 66.7581 7.52763L65.2412 9.75036C66.5693 10.7553 68.2095 11.2595 69.8729 11.1743L69.7803 11.128Z" fill="white"/>
    <path d="M79.3662 11.1287C81.9368 11.1287 83.5 9.78577 83.5 7.65565C83.5 6.19699 82.6084 5.20139 80.7673 4.7962L78.9609 4.39102C78.2778 4.22895 77.9188 4.00899 77.9188 3.6038C77.9188 3.19862 78.2546 2.94393 79.0767 2.94393C80.0265 2.97586 80.9339 3.34527 81.6358 3.98584L83.1526 1.76311C81.9648 0.925959 80.5274 0.517706 79.0767 0.605435C76.6682 0.605435 75.0471 1.76311 75.0471 3.81219C75.0313 4.53955 75.289 5.24638 75.7693 5.79297C76.2496 6.33957 76.9174 6.68616 77.6409 6.76425L79.4357 7.19258C80.3273 7.38939 80.5936 7.65566 80.5936 8.00296C80.5936 8.35026 80.2347 8.68598 79.4357 8.68598C78.3033 8.66376 77.2124 8.2553 76.344 7.52831L74.8271 9.75104C76.1553 10.756 77.7954 11.2602 79.4589 11.175L79.3662 11.1287Z" fill="white"/>
  </svg>
);
