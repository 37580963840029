import React from 'react';
import Header from '../components/Header';
import LoginView from '../views/LoginView';
import Footer from '../components/Footer';
import PageLayout from '../components/Layout/PageLayout';

export default function Login() {
  return (
    <PageLayout>
      <Header />
      <LoginView />
      <Footer />
    </PageLayout>
  );
}
