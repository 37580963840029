import React from 'react';
import { Text } from '@dls/web';
import styled from 'styled-components';

const SINGPASS_LINK = 'https://app.singpass.gov.sg/share';

const Wrapper = styled.div`
  margin-top: 4px;
  text-align: center;
`;

export default function SingPassFooter() {
  return (
    <Wrapper>
      <Text type="link" href={SINGPASS_LINK} target="_blank">
        Don&apos;t have Singpass app?
      </Text>
    </Wrapper>
  );
}
