/* istanbul ignore file */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import { getRecoil } from 'recoil-nexus'
import { Button} from '@dls/web';
import { SingpassLoginIcon } from "../../icons/SingpassLoginIcon";
import { randomChars, pushAnalyticsEvent } from '../../helpers/utils';
import { flowConfigState } from '../../index';

declare global {
  interface Window {
    NDI?: any;
    gtag?: any;
  }
}

const Container = styled.div`
  .ndi-qr-page {
    justify-content: center;
  }
  .ndi-qr-page > div:first-child {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
  &:first-child {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  &&& button {
    width: 224px;
  }
  p {
    font-weight: bold;
  }
  svg {
    padding: 0 0 0 6px;
    vertical-align: text-bottom;
  }
`;


export default function SingPassQR(props) {

  const [loading, setLoading] = useState(false);

  function handleredirect() {
    setLoading('inProgress');
    const qs = queryString.parse(location.search);
    const uuid1 = randomChars(); // 16 chars
    const uuid2 = randomChars(); // 16 chars
    const redirect_uri = new URL(props.callback, window.location.origin);
  
    const params = {
      scope: 'openid',
      response_type: 'code',
      redirect_uri: redirect_uri.href,
      nonce: uuid1,
      client_id: props.clientId,
      state: `${uuid2}--${uuid1}--${qs.flowId}`
    };

    const url = `${props.authEndpoint}?${Object.entries(params)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join("&")}`
    
    const flowConfig = getRecoil(flowConfigState);

    pushAnalyticsEvent(
      'uiam_login_singpass',
      'singpass_login_start',
      `${flowConfig?.portal} - Login with Singpass started`, 
    );
    
    window.open(url, '_self');
  }

  useEffect(() => {

  }, []);

  return (
    <Container>
      <ButtonWrapper>
          <Button
            primary
            loading={loading}
            onClick={() => handleredirect() }
          >
            Log in with
            <span>
            <SingpassLoginIcon />
            </span>
          </Button>
        </ButtonWrapper>
    </Container>
  );
}

